<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Ubah Jenis Transport</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="nip">Nama Jenis Transport<strong>*</strong></label>
                  <input type="text" class="form-control" id="name" v-model="form.name">
                </b-col>
              </div>
              <div class="float-right">
                <button type="submit" class="btn bg-danger mr-1" @click.prevent="formCancel()">Batal</button>
                <button type="submit" class="btn btn-primary" @click.prevent="handleUpdate()">Simpan</button>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getDataService, putDataService } from '../../../store/modules/crudservices'

export default {
  name:'JenisTransportEdit',
  data() {
    return {
      form:{
        name: null
      },
      role: []
    }
  },
  methods: {
    getData(id) {
      const url = '/jenis-transport/' + id;
      getDataService(url).then(response => {
        this.form = response.data
      })
    },
    handleUpdate(){
      const form = this.form
			const _data = {
				'_method': 'PUT' ,
				'name': form.name
			}
			const url = '/jenis-transport/' + form.id
      putDataService(url, _data).then(response => {
        if(response.data.success) this.$router.push('/pendukung/jenis-transport')
      })
    },
    formCancel(){
      this.$router.push('/pendukung/jenis-transport') 
    },
  },
  mounted() {
    this.getData(this.$route.params.id)
  }
}
</script>